import React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import ViewApiUser from '../component/ViewApiUser';

export const Dashboard = (props: any) => {

    return <div>
        <Box sx={{ ml: 3, mr: 3 , mt: 6 }}>
            <Alert severity="info">
                <Typography align='left'>
                    Hello, {props.user.firstName}!, Welcome to iion api management console.
                    You can add new API and also create generated API key and secret key for our clients.
                </Typography>
            </Alert>
        </Box>
        <Box sx={{ padding: 2 }}>
            <ViewApiUser {...props} />
        </Box>
    </div>
}
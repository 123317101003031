import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import logo from '../../static/iion-logo-negative-version.svg'

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

// Handle logout function
const onLogout = useCallback(() => {
  props.handleLogout();
  navigate('/');
}, [navigate, props]);

  useEffect(()=> {
    if(location.pathname==='/'){
      onLogout();
    }
  }, [location.pathname, onLogout])

  const isActive = (path: string) => location.pathname === path;

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* Navigation links */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Button
              color='inherit'
              onClick={() => navigate(`/`)}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start', 
                padding: 0,
              }}
            >
              <img
                        src={logo}
                        alt="Iion"
                        style={{
                            width: '30%',
                            height: '30%',
                            objectFit: 'contain',
                        }}
                    />
            </Button>
            {/* Add more navigation links here if needed */}
          </Box>

          {props.user && (
            <Typography sx={{ mr: 2 }}>
              Welcome, {props.user.firstName}
            </Typography>
          )}
          <Button
            color="inherit"
            variant="outlined"
            onClick={onLogout}
            sx={{
              borderColor: 'white',
              color: 'white',
              '&:hover': {
                borderColor: '#ccc',
              },
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <AppBar
        position="static"
        color="default"
        sx={{
          boxShadow: 'none',
          backgroundColor: '#f5f5f5',
          height: '40px',
        }}
      >
        <Toolbar
          sx={{
            minHeight: '40px',
            padding: 0,
            marginTop: '-10px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>

            <Button
              color={isActive('/dashboard') ? 'secondary' : 'primary'}
              onClick={() => navigate(`/dashboard`)}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem',
                padding: '0',
                borderBottom: isActive('/dashboard') ? '2px solid #1976d2' : 'none',
              }}
            >
              Users
            </Button>
            
            <Divider orientation="vertical" flexItem />
            <Button
              color={isActive('/scheduleJobs') ? 'secondary' : 'primary'}
              onClick={() => navigate(`/scheduleJobs`)}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem',
                padding: '0',
                borderBottom: isActive('/scheduleJobs') ? '2px solid #1976d2' : 'none',
              }}
            >
              Schedulers
            </Button>

            <Divider orientation="vertical" flexItem />
            <Button
              color={isActive('/schedulerLogs') ? 'secondary' : 'primary'}
              onClick={() => navigate(`/schedulerLogs`)}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem',
                padding: '0',
                borderBottom: isActive('/schedulerLogs') ? '2px solid #1976d2' : 'none',
              }}
            >
              Scheduler Logs
            </Button>

            <Divider orientation="vertical" flexItem />
            <Button
              color={isActive('/manageData') ? 'secondary' : 'primary'}
              onClick={() => navigate(`/manageData`)}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem',
                padding: '0',
                borderBottom: isActive('/manageData') ? '2px solid #1976d2' : 'none',
              }}
            >
              Manage Data
            </Button>
            
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
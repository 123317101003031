import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridFilterModel, GridFilterOperator, GridPaginationModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import { getSchedulerLogs } from '../api/Api';
import { formatDate, formatDateInAustralia, formatDateInUTC, formatMilliseconds } from '../common/DateTimeConverter';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Search } from '@mui/icons-material';


export interface ScheduleTableType {
    id: number;
    tableId: number;
    tableName: string;
    status: string;
    error: string;
    createdDate: string;
    updatedDate: string;
    processTime: string;
}

const SchedulerLogs = ({ handleSnackBar }: any) => {
    const [tableList, setTableList] = useState<ScheduleTableType[]>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 20 });
    const [tempfilterModel, setTempFilterModel] = useState<GridFilterModel>({ items: [] });
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'updatedDate', sort: 'desc' }]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState({ searchField: '', search: '' });
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [saveDate, setSaveDate] = useState({ startDate: startDate, endDate: endDate });

    const statusFilterOperator: GridFilterOperator = {
        label: 'Filter by Status',
        value: 'status',
        getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
                return null;
            }
            return ({ value }) => {
                return value === filterItem.value;
            };
        },
        InputComponent: (props) => (
            <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                    value={props.item.value || ''}
                    onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
                    label="Status"
                >
                    <MenuItem value="STARTED">STARTED</MenuItem>
                    <MenuItem value="FINISHED">FINISHED</MenuItem>
                    <MenuItem value="FAILED">FAILED</MenuItem>
                </Select>
            </FormControl>
        ),
    };

    const listOfTable = useCallback(() => {
        console.log("filter ", filter, paginationModel, sortModel);
        const filterRequest = {
            page: paginationModel.page,
            pageSize: paginationModel.pageSize,
            sortingOn: sortModel[0]?.field,
            sortingDirection: sortModel[0]?.sort,
            searchField: filter?.searchField,
            search: filter?.search,
            startDate: saveDate.startDate,
            endDate: saveDate.endDate,
        }
        setLoading(true)
        getSchedulerLogs(filterRequest).then(response => {
            const rows: ScheduleTableType[] = [];
            response.data.content.forEach((data: any) => {
                const row = {
                    id: data.id,
                    tableId: data.scheduleJob?.id,
                    tableName: data.scheduleJob?.tableName,
                    status: data.status,
                    error: data.error,
                    australiaTime: data.createdDate,
                    createdDate: data.createdDate,
                    updatedDate: data.updatedDate,
                    processTime: formatMilliseconds(data.processTime),
                }
                rows.push(row);
            })
            setRowCount(response.data.totalElements);
            setTableList(rows);
        }).catch(error => {
            handleSnackBar(true, "error", error.response?.data.message);
        }).finally(() =>
            setLoading(false)
        );
    }, [filter, paginationModel, sortModel, saveDate, handleSnackBar]);

    useEffect(() => {
        listOfTable();
    }, [listOfTable]);


    const columns: GridColDef[] = [
        { field: 'tableId', headerName: 'Table ID', flex: 8, filterable: false },
        {
            field: 'tableName',
            headerName: 'TableName',
            flex: 20
        },
        {
            field: 'error',
            headerName: 'Error',
            flex: 20,
            renderCell: (params) =>{
                return (
                    <Tooltip title={params.value}>
                      <span>{params.value}</span>
                    </Tooltip>
                  );
            }
        },
        {
            field: 'australiaTime',
            headerName: 'Australia Time',
            flex: 18,
            renderCell: (params) =>{
                console.log("params : ", params.value);
                const datetime = formatDateInAustralia(params.value);
                return datetime;
            }
        },
        {
            field: 'createdDate',
            headerName: 'Stated Time',
            flex: 18,
            filterable: false,
            renderCell: (params) =>{
                const utc = formatDateInUTC(params.value);
                const datetime = formatDate(params.value)
                return (
                    <Tooltip title={`UTC : ${ utc }`}>
                      <span>{datetime}</span>
                    </Tooltip>
                  );
            }
        },
        {
            field: 'updatedDate',
            headerName: 'Finished Time',
            flex: 18,
            filterable: false,
            renderCell: (params) =>{
                const utc = formatDateInUTC(params.value);
                const datetime = formatDate(params.value)
                return (
                    <Tooltip title={`UTC : ${ utc }`}>
                      <span>{datetime}</span>
                    </Tooltip>
                  );
            }
        }, {
            field: 'processTime',
            headerName: 'Process Time',
            flex: 12,
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 12,
            filterOperators: [statusFilterOperator],
            renderCell: (params) => {
                const status = params.value;
    
                let severity: 'success' | 'error' | 'warning';
                let displayText: string;
    
                switch (status) {
                    case 'FINISHED':
                        severity = 'success';
                        displayText = 'Finished';
                        break;
                    case 'FAILED':
                        severity = 'error';
                        displayText = 'Failed';
                        break;
                    case 'STARTED':
                    default:
                        severity = 'warning';
                        displayText = 'Started';
                        break;
                }
    
                return (
                    <Alert severity={severity} sx={{ padding: '0 8px', display: 'flex', alignItems: 'center' }}>
                        {displayText}
                    </Alert>
                );
            },
        },
    ];

    const handlePaginationChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    const handleFilterChange = useCallback((newFilterModel: GridFilterModel) => {
        console.log("newFilterModel ", newFilterModel);
        setTempFilterModel(newFilterModel);
        if (newFilterModel.items && newFilterModel.items.length > 0) {
            const field = newFilterModel.items[0].field;
            const value = newFilterModel.items[0].value;
            if (value && value.length > 2) {
                setFilter({
                    searchField: field,
                    search: value
                });
            }
        }
    }, []);

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    };

    const onSearch = () => {
        if (startDate && endDate) {
            setSaveDate({
                startDate: startDate,
                endDate: endDate
            })
        }
    }

    return (
        <Box sx={{ padding: 6 }}>
            <Box>
                <Alert variant="standard" severity="info">
                    <Typography align='left'>
                        This will give info about our schedule Jobs.
                    </Typography>
                </Alert>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 3, mb: 4 }}>
                <Button variant="outlined" onClick={() => window.history.back()}>Back</Button>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker label="Start Date time"
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)} />
                        <DateTimePicker label="End Date time"
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)} />
                    </LocalizationProvider>
                    <Button size='small' onClick={onSearch}> <Search /></Button>
                </Box>
            </Box>
            <div style={{ height: 800, width: '100%' }}>
                <DataGrid
                    loading={loading}
                    rows={tableList}
                    columns={columns}
                    paginationModel={paginationModel}
                    rowCount={rowCount}
                    pageSizeOptions={[20, 50, 100]}
                    paginationMode="server"
                    onPaginationModelChange={handlePaginationChange}
                    filterModel={tempfilterModel}
                    onFilterModelChange={handleFilterChange}
                    filterMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    slots={{ toolbar: GridToolbar }}
                    disableDensitySelector
                />
            </div>
        </Box>
    );
};

export default SchedulerLogs;

import { Alert, Box, Typography } from '@mui/material';
import React from 'react';

export const ManageData = (props: any) => {

    return <div>
    <Box sx={{ ml: 3, mr: 3 , mt: 6 }}>
        <Alert severity="info">
            <Typography align='left'>
                Cooming soon
            </Typography>
        </Alert>
    </Box>
</div>
}
import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { loginApi } from '../api/Api';
import CustomImage from '../../static/iion-logo-main-version.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const defaultTheme = createTheme();

export const Login = (props: any) => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear();
        props.handleClick(false);
    }, [props])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        loginApi(data).then(response => {
            const userData = response.data.data;
            const user = {
                'firstName': userData.firstName,
                'lastName': userData.lastName
            }
            localStorage.setItem("userInfo", JSON.stringify(user));
            props.setLoggedInUser(user);
            props.handleClick(true);
            navigate("/dashboard");
        }).catch(error => {
            if (error.code === 'ERR_NETWORK') {
                props.handleSnackBar(true, "error", 'ERR_NETWORK')
            } else {
                props.handleSnackBar(true, "error", error.response.data.message)
            }
        });

    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mt: 4,
                    mb: 4,
                    mr: 6,
                }}
            >
                <Box>
                    <img
                        src={CustomImage}
                        alt="Iion"
                        style={{
                            width: '30%',
                            height: '30%',
                            objectFit: 'contain',
                        }}
                    />
                </Box>
                <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        mt:2
                      }}>
                    <HelpOutlineIcon color="primary" />
                    <Typography
                        component="a"
                        href="https://documenter.getpostman.com/view/3435377/2s9YsNdAEJ"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body2"
                        sx={{
                            textDecoration: 'none',
                            color: 'primary.main',
                            fontWeight: 'bold',
                        }}
                    >
                        Documentation
                    </Typography>
                </Box>
            </Box>

            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Admin Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}